import React, { lazy } from 'react';
import Confirmation from 'antd/pages/public/Confirmation';
import SignUp from 'antd/pages/public/Signup';
import Login from 'antd/pages/public/Login';
import ResetPassword from 'antd/pages/public/ResetPassowrd';
import { RoutesNames } from './RoutesNames';

const Intro = lazy(() => import('Pages/Intro'));
const Profile = lazy(() => import('Pages/Profile'));
// const Properties = lazy(() => import('Pages/Properties'));
const Properties = lazy(() => import('antd/pages/private/Properties/PropertiesTable'));
const Contracts = lazy(() => import('Pages/Contracts'));
// const Residents = lazy(() => import('Pages/Residents'));
const Residents = lazy(() => import('antd/pages/private/Residents/ResidentsTable'));
const ResidentsDetails = lazy(() => import('antd/pages/private/Residents/ResidentDetails'));
const ApplicantDetails = lazy(() => import('antd/pages/private/Residents/ApplicantDetails'));
// const Animals = lazy(() => import('Pages/Animals'));
const Animals = lazy(() => import('antd/pages/private/Animals/'));

const CurrentAndMovedOutAnimalDetails = lazy(() =>
  import('antd/pages/private/Animals/CurrentAndMovedOutAnimalDetails')
);
const ApplicantAnimalDetail = lazy(() => import('antd/pages/private/Animals/ApplicantAnimalDetail'));
const AnimalsVisitationDetails = lazy(() => import('antd/pages/private/Animals/VisitationAnimalDetail'));
const Maintainers = lazy(() => import('Pages/Maintainers'));
const Maintainer = lazy(() => import('Pages/Maintainer'));
// const PropertyManagers = lazy(() => import('Pages/PropertyManagers'));
const PropertyManagers = lazy(() => import('antd/pages/private/PropertyManagers/PropertyManagersTable'));
// const PropertyManager = lazy(() => import('Pages/PropertyManager'));
const PropertyManager = lazy(() => import('antd/pages/private/PropertyManagers/PropertyManagersDetails'));
const Violations = lazy(() => import('Pages/Violations'));
const Support = lazy(() => import('antd/pages/private/Support'));
const Contract = lazy(() => import('Pages/Contract'));
const Applications = lazy(() => import('Pages/Applications'));
const Policy = lazy(() => import('Pages/Policy'));
const DefaultPolicy = lazy(() => import('Pages/DefautlPolicy'));
// const Property = lazy(() => import('Pages/Property'));
const Property = lazy(() => import('antd/pages/private/Properties/PropertiesDetails'));
const Onboarding = lazy(() => import('Pages/Onboarding'));
const DocumentsAdd = lazy(() => import('Pages/DocumentsAdd'));
const IntegrationPage = lazy(() => import('antd/pages/private/Integration'));
const PaymentSettings = lazy(() => import('Pages/Settings/PaymentSettings'));
const LandlordTable = lazy(() => import('Pages/LandlordTable'));
const SuggestedTexts = lazy(() => import('Pages/SuggestedTexts'));
const LandlordDetail = lazy(() => import('Pages/Admin/Landlord/LandlordDetail'));
const Dashboard = lazy(() => import('Pages/Dashboard'));
const PolicyInput = lazy(() => import('Pages/PolicyInput'));
// const TenantPaymentSettings = lazy(() => import('Pages/TenantPaymentSettings'));
// const TenantContracts = lazy(() => import('Pages/TenantContracts'));
// const TenantContractView = lazy(() => import('Pages/TenantContractView'));
const Curriculum = lazy(() => import('Pages/Curriculum'));
const Analytics = lazy(() => import('antd/pages/private/Analytics'));
const SubCurriculum = lazy(() => import('Pages/Curriculum/SubCurriculum'));
const CurriculumDetail = lazy(() => import('Pages/Curriculum/CurriculumDetail'));
const Complaints = lazy(() => import('Pages/Complaints'));
const ComplaintDetails = lazy(() => import('antd/pages/private/Complaints/ComplaintsDetails/ComplaintDetails'));
const NoticeDetails = lazy(() => import('antd/pages/private/Complaints/ComplaintsDetails/NoticeDetails'));
const AllRoommates = lazy(() => import('antd/pages/private/AllRoommates/YardiAllRoommates'));
const LeaseDetails = lazy(() => import('antd/pages/private/AllRoommates/YardiAllRoommates'));
const Notificiations = lazy(() => import('antd/pages/private/Settings/Notifications'));
const AccountSettings = lazy(() => import('antd/pages/private/Settings/AccountSettings'));

export const allowedUserTypes = {
  landlord: 'landlord',
  admin: 'admin',
  propertyManager: 'propertyManager',
};

const { landlord, admin, propertyManager } = allowedUserTypes;

export const RoutesData = [
  {
    path: RoutesNames.Analytics,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: Analytics,
  },
  {
    path: RoutesNames.Intro,
    isPrivate: true,
    allowedUserTypes: [landlord, admin],
    component: Intro,
  },
  {
    path: RoutesNames.Profile,
    isPrivate: true,
    allowedUserTypes: [landlord, admin, propertyManager],
    component: Profile,
  },
  {
    path: RoutesNames.IndividualProperty,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Property,
  },
  {
    path: RoutesNames.Properties,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Properties,
  },
  {
    path: RoutesNames.IndividualContract,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Contract,
  },
  {
    path: RoutesNames.Contracts,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Contracts,
  },
  {
    path: RoutesNames.Applications,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Applications,
  },
  {
    path: RoutesNames.AnimalsVisitation,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.EmployeeAnimals,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.AnimalsVisitationDetails,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: AnimalsVisitationDetails,
  },
  {
    path: RoutesNames.Residents,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Residents,
  },
  {
    path: RoutesNames.ResidentDetails,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: ResidentsDetails,
  },
  {
    path: RoutesNames.ApplicantDetails,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: ApplicantDetails,
  },
  {
    path: RoutesNames.Animals,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.AnimalCurrentDetails,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: CurrentAndMovedOutAnimalDetails,
  },
  {
    path: RoutesNames.AnimalMovedOutDetails,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: CurrentAndMovedOutAnimalDetails,
  },
  {
    path: RoutesNames.AddedPetsDetails,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: CurrentAndMovedOutAnimalDetails,
  },
  {
    path: RoutesNames.AnimalApplicantDetails,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: ApplicantAnimalDetail,
  },
  {
    path: RoutesNames.AnimalsAll,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.AnimalsCurrent,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.AnimalsMovedOut,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.AnimalsApplicant,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.AddedPets,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.AnimalsPrevented,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Animals,
  },
  {
    path: RoutesNames.Maintainers,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Maintainers,
  },
  {
    path: RoutesNames.IndividualMaintainer,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Maintainer,
  },
  {
    path: RoutesNames.PropertyManagers,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: PropertyManagers,
  },
  {
    path: RoutesNames.PropertyManager,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: PropertyManager,
  },
  {
    path: RoutesNames.Violations,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Violations,
  },
  {
    path: RoutesNames.Support,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Support,
  },
  // {
  //   path: RoutesNames.Requests,
  //   isPrivate: true,
  //   allowedUserTypes: [landlord, tenant, admin, propertyManager],
  //   component: ApplicationsV2,
  // },
  {
    path: RoutesNames.Curriculum,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, admin, propertyManager],
    component: Curriculum,
  },
  {
    path: RoutesNames.SubCurriculum,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, admin, propertyManager],
    component: SubCurriculum,
  },
  {
    path: RoutesNames.CurriculumDetail,
    isPrivate: true,
    allowedUserTypes: [landlord, admin, propertyManager],
    component: CurriculumDetail,
  },
  {
    path: RoutesNames.Policies,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Policy,
  },
  {
    path: RoutesNames.DefaultPolices,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: DefaultPolicy,
  },
  {
    path: RoutesNames.Onboarding,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: Onboarding,
  },
  {
    path: RoutesNames.Payment,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: PaymentSettings,
  },
  {
    path: RoutesNames.PropertyIntegration,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: IntegrationPage,
  },
  {
    path: RoutesNames.Integrations,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: IntegrationPage,
  },
  {
    path: RoutesNames.Documents,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: DocumentsAdd,
  },
  {
    path: RoutesNames.IndividualLandlord,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: LandlordDetail,
  },
  {
    path: RoutesNames.Landlords,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: LandlordTable,
  },
  {
    path: RoutesNames.RedirectComplaint,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: LandlordTable,
  },
  {
    path: RoutesNames.RedirectNotice,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: LandlordTable,
  },
  {
    path: RoutesNames.SuggestedTexts,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: SuggestedTexts,
  },
  {
    path: RoutesNames.Dashboard,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Dashboard,
  },
  {
    path: RoutesNames.Complaints,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Complaints,
  },
  {
    path: RoutesNames.Complaint,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Complaints,
  },
  {
    path: RoutesNames.Notice,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Complaints,
  },
  {
    path: RoutesNames.ComplaintDetails,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: ComplaintDetails,
  },
  {
    path: RoutesNames.NoticeDetails,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: NoticeDetails,
  },
  {
    path: RoutesNames.StatePolicy,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: PolicyInput,
  },
  {
    path: RoutesNames.ResetPassword,
    exact: true,
    component: ResetPassword,
  },
  {
    path: RoutesNames.Confirmation,
    exact: true,
    component: Confirmation,
  },
  {
    path: RoutesNames.SignIn,
    exact: true,
    component: Login,
  },
  {
    path: RoutesNames.SignUp,
    exact: true,
    component: SignUp,
  },
  {
    path: RoutesNames.AllRoommates,
    isGlobal: true,
    component: AllRoommates,
  },
  {
    path: RoutesNames.LeaseDetails,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: LeaseDetails,
  },
  {
    path: RoutesNames.Notifications,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager, admin],
    component: Notificiations,
  },
  {
    path: RoutesNames.AccountSettings,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager, admin],
    component: AccountSettings,
  },
];
