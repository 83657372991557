import { useIntl } from 'react-intl';
import HelpIcon from '@material-ui/icons/Help';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SignalCellularAltIcon from '@material-ui/icons/BarChart';

import {
  DashboardIcon,
  DocumentIcon,
  GraduationHatIcon,
  HomeIcon,
  NotebookIcon,
  PetPawIcon,
  SettingsSidebarIcon,
  SupportStaffIcon,
  ViolationsIcon,
} from 'components/BaseComponents/Icons';
import { allowedUserTypes } from 'routes/RoutesData';
import { useTabRelocation } from 'api/apiHooks/relocationHook';

const defaultTabs = [
  {
    label: 'dashboard',
    route: 'dashboard',
    icon: <DashboardIcon />,
    dataCy: 'dashboard',
  },
  {
    label: 'animals',
    route: 'animals',
    icon: <ViolationsIcon />,
  },
  {
    label: 'residents',
    route: 'residents',
    icon: <SupervisorAccountIcon />,
  },
  {
    label: 'properties',
    route: 'properties',
    icon: <HomeIcon />,
    dataCy: 'properties',
  },
  {
    label: 'team-members',
    route: 'property-managers',
    icon: <SupportStaffIcon />,
    dataCy: 'managers',
    // subRoutes: [
    //   { label: 'property-managers', route: 'property-managers', dataCy: 'propertyManagers' },
    //   { label: 'maintainers', route: 'maintainers', dataCy: 'maintainers' },
    // ],
  },
  {
    label: 'policies',
    route: 'policies',
    icon: <DocumentIcon />,
    dataCy: 'policies',
    // subRoutes: [
    //   { label: 'policies', route: 'policies', dataCy: 'policies' },
    //   { label: 'default-policies', route: 'default-policies', dataCy: 'default-policies' },
    // ],
  },
];

export const useTabList = ({ impersonate, profile }) => {
  const { formatMessage } = useIntl();
  const { refetch: redirectTab } = useTabRelocation();
  const { landlord, admin, propertyManager } = allowedUserTypes;

  return {
    [landlord]: [
      ...defaultTabs.map((tab) => ({
        ...tab,
        label: formatMessage({ id: `tabs.${tab.label}` }),
        subRoutes: (tab.subRoutes || []).map((sub) => ({
          ...sub,
          label: formatMessage({ id: `tabs.${sub.label}` }),
        })),
      })),
      ...(profile.hasAccessToCourses
        ? [
            {
              label: formatMessage({ id: `tabs.curriculum` }),
              route: 'curriculum',
              icon: <GraduationHatIcon color='#FCC425' />,
            },
          ]
        : []),
      {
        label: formatMessage({ id: `tabs.complaints` }),
        route: 'complaints',
        icon: <ViolationsIcon />,
        dataCy: 'complaints',
      },
      {
        label: formatMessage({ id: `tabs.settings` }),
        route: 'settings',
        icon: <SettingsSidebarIcon />,
        subRoutes: [
          { label: formatMessage({ id: `tabs.payments` }), route: 'settings' },
          { label: formatMessage({ id: `tabs.integrations` }), route: 'integration-settings' },
          { label: formatMessage({ id: `tabs.notifications` }), route: `notifications/${profile.id}` },
          { label: formatMessage({ id: `tabs.account_settings` }), route: 'account-settings' },
        ],
      },
      {
        label: formatMessage({ id: `tabs.support` }),
        route: 'support',
        icon: <HelpIcon />,
        dataCy: 'support',
      },
    ],
    [propertyManager]: [
      ...defaultTabs.map((tab) => ({
        ...tab,
        label: formatMessage({ id: `tabs.${tab.label}` }),
        subRoutes: (tab.subRoutes || []).map((sub) => ({
          ...sub,
          label: formatMessage({ id: `tabs.${sub.label}` }),
        })),
      })),
      ...(profile.hasAccessToCourses
        ? [
            {
              label: formatMessage({ id: `tabs.curriculum` }),
              route: 'curriculum',
              icon: <GraduationHatIcon color='#FCC425' />,
            },
          ]
        : []),
      {
        label: formatMessage({ id: `tabs.complaints` }),
        route: 'complaints',
        icon: <ViolationsIcon />,
        dataCy: 'complaints',
      },
      ...(impersonate
        ? []
        : [
            {
              label: formatMessage({ id: `tabs.support` }),
              route: 'support',
              icon: <HelpIcon />,
              dataCy: 'support',
            },
          ]),
      {
        label: formatMessage({ id: `tabs.settings` }),
        route: `notifications/${profile.id}`,
        icon: <SettingsSidebarIcon />,
        subRoutes: [
          { label: formatMessage({ id: `tabs.notifications` }), route: `notifications/${profile.id}` },
          { label: formatMessage({ id: `tabs.account_settings` }), route: 'account-settings' },
        ],
      },
    ],
    [admin]: [
      {
        label: formatMessage({ id: `tabs.animals`, defaultMessage: 'Animals' }),
        route: 'animals',
        icon: <PetPawIcon />,
        isButton: true,
        onClick: () => redirectTab('/animals'),
      },
      {
        label: formatMessage({ id: `tabs.applications` }),
        route: 'applications',
        icon: <NotebookIcon />,
        isButton: true,
        onClick: () => redirectTab('/applications'),
      },
      {
        label: formatMessage({ id: `tabs.tenants` }),
        route: 'tenants',
        icon: <SupervisorAccountIcon />,
        isButton: true,
        onClick: () => redirectTab('/tenants'),
      },
      {
        label: formatMessage({ id: `tabs.landlords` }),
        route: 'landlords',
        icon: <BusinessIcon />,
      },
      {
        label: formatMessage({ id: `tabs.curriculum` }),
        route: 'curriculum',
        icon: <GraduationHatIcon color='#FCC425' />,
      },
      {
        label: formatMessage({ id: `tabs.state-policy` }),
        route: 'state-policy',
        icon: <DescriptionIcon />,
      },
      {
        label: formatMessage({ id: `tabs.documents` }),
        route: 'documents',
        icon: <ViolationsIcon />,
      },
      {
        label: formatMessage({ id: `tabs.suggested-texts` }),
        route: 'suggested-texts',
        icon: <FontDownloadIcon />,
      },
      {
        label: formatMessage({ id: `tabs.analytics` }),
        route: 'analytics',
        icon: <SignalCellularAltIcon />,
      },
    ],
  };
};
