import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'antd/data/types/converter.types';
import {
  EPropertyStatusDB,
  EPropertyStatusUI,
  EPropertiesTemplateTypeDB,
  EPropertiesTemplateTypeUI,
  EPropertySourceDB,
  EPropertySourceUI,
  TAssignPropertyDB,
  TAssignPropertyUI,
  TCreatePropertyDB,
  TCreatePropertyUI,
  TInvitePropertyManagerUI,
  TInvitePropertyMangerDB,
  TPetChargeStatusesUploadDB,
  TPetChargeStatusesUploadUI,
  TPropertiesCSVUploadResultDB,
  TPropertiesCSVUploadResultUI,
  TPropertiesDB,
  TPropertiesTypeDB,
  TPropertiesTypeUI,
  TPropertiesUI,
  TPropertiesUploadUI,
  TPropertyDB,
  TPropertyManagerPropertiesDB,
  TPropertyManagerPropertiesUI,
  TPropertyManagersTableDB,
  TPropertyManagersTableUI,
  TPropertyTypeDB,
  TPropertyTypeUI,
  TPropertyUI,
  TUnassignedPropertiesDB,
  TUnassignedPropertiesUI,
  TUnitDB,
  TUnitUI,
  TPropertyUploadedFilesUI,
  TPropertyUploadedFilesDB,
  EUploadedFileTypeUI,
  EUploadedFileTypeDB,
  TResidentsScreeningActionUI,
  TResidentsScreeningActionDB,
  TAddUnitsUI,
  TAddUnitsDB,
  ENotificationTypesUI,
  ENotificationTypesDB,
  TAssignedPropertiesUI,
  TAssignedPropertiesDB,
  TPropertyMetadataDB,
  TPropertyMetadataUI,
} from 'antd/data/types/property.types';
import { b64toBlob } from 'antd/api/sevices/fileServices';
import {
  dateTimeFormat,
  formatStateLabel,
  formattedDate,
  formattedDateTimezone,
  formatToSnakeCase,
} from 'antd/helpers/utils';
import { selectOptionConverter } from './general.converters';

export const propertiesGetConverter: TFetchConverter<TPropertyUI, TPropertyDB> =
  {
    fromDb: (data) => {
      return {
        name: data.name,
        value: data.id,
      };
    },
  };

export const getUnitsCoverter: TFetchConverter<TUnitUI, TUnitDB> = {
  fromDb: (data) => {
    return {
      name: data.number,
      value: data.id,
    };
  },
};

export const propertyTypeConverter: TFetchConverter<
  TPropertyTypeUI,
  TPropertyTypeDB
> = {
  fromDb: (data) => ({
    title: data.displayName,
    keyWord: data.key,
  }),
};

export const propertiesTemplateTypeConverter: TConvertor<
  EPropertiesTemplateTypeUI,
  EPropertiesTemplateTypeDB
> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EPropertiesTemplateTypeDB]: EPropertiesTemplateTypeUI;
    } = {
      [EPropertiesTemplateTypeDB.OPP]: EPropertiesTemplateTypeUI.OPP,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EPropertiesTemplateTypeUI]: EPropertiesTemplateTypeDB;
    } = {
      [EPropertiesTemplateTypeUI.OPP]: EPropertiesTemplateTypeDB.OPP,
    };
    return dataMapper[data];
  },
};
export const propertyCsvUploadConverter: TAuthConverter<
  TPropertiesUploadUI,
  FormData,
  TPropertiesCSVUploadResultUI,
  TPropertiesCSVUploadResultDB
> = {
  toDb: (data) => {
    const formData = new FormData();
    formData.append('type', propertiesTemplateTypeConverter.toDb(data.type));
    data.file.forEach((item) => {
      formData.append('file', new Blob([item], { type: 'application/json' }));
    });
    return formData;
  },
  fromDb: (data) => {
    let file = null;
    if (data.skippedProperties) {
      file = b64toBlob(data.skippedProperties, 'text/csv');
    }
    return {
      skippedProperties: file,
      skippedPropertiesCount: data.skippedPropertiesCount,
      removedPropertiesCount: data.removedPropertiesCount,
      addedPropertiesCount: data.addedPropertiesCount,
      matchedPropertiesCount: data.matchedPropertiesCount,
      totalPropertiesCount: data.totalPropertiesCount,
    };
  },
};
export const propertySourceConverter: TFetchConverter<
  EPropertySourceUI,
  EPropertySourceDB
> = {
  fromDb: (data) => {
    const dataMapper: { [key in EPropertySourceDB]: EPropertySourceUI } = {
      [EPropertySourceDB.RESMAN]: EPropertySourceUI.RESMAN,
      [EPropertySourceDB.SYSTEM]: EPropertySourceUI.SYSTEM,
    };
    return dataMapper[data];
  },
};

export const propertyStatusConverter: TFetchConverter<
  EPropertyStatusUI,
  EPropertyStatusDB
> = {
  fromDb: (data) => {
    const dataMapper: { [key in EPropertyStatusDB]: EPropertyStatusUI } = {
      [EPropertyStatusDB.ACTIVE]: EPropertyStatusUI.ACTIVE,
      [EPropertyStatusDB.PENDING]: EPropertyStatusUI.PENDING,
    };
    return dataMapper[data];
  },
};

export const propertiesConverter: TFetchConverter<
  TPropertiesUI,
  TPropertiesDB
> = {
  fromDb: (data) => {
    return {
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      source: propertySourceConverter.fromDb(data.source),
      state: formatStateLabel(data.state),
      type: data.type,
      unitCount: data.unitCount,
      zip: data.zip,
      defaultPolicy: data.defaultPolicy,
      status: propertyStatusConverter.fromDb(data.status),
    };
  },
};

export const createPropertyConverter: TCreateConverter<
  TCreatePropertyUI,
  TCreatePropertyDB
> = {
  toDb: (data) => {
    return {
      name: data.name,
      address1: data.address1,
      address2: data.address2,
      state: formatToSnakeCase(data.state),
      zip: data.zip,
      defaultPolicyId: data.defaultPolicyId,
      type: data.type,
      city: data.city,
      status: data.state,
      unitCount: data.unitCount,
      unitNumbers: data.unitNumbers,
      userId: data.userId,
      photoUrl: data.photoUrl,
      screenExistingTenants: !!data?.screenExistingTenants,
    };
  },
};

export const propertyDetailsConverter: TFetchConverter<
  TPropertiesTypeUI,
  TPropertiesTypeDB
> = {
  fromDb: (data) => {
    return {
      canEditScreenExistingTenantsBeforeOnboarding:
        data.canEditScreenExistingTenantsBeforeOnboarding,
      isScreeningEnabled: data.isScreeningEnabled,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      id: data.id,
      name: data.name,
      photoUrl: data.photoUrl,
      source: propertySourceConverter.fromDb(data.source),
      state: data.state,
      type: data.type,
      unitCount: data.unitCount,
      zip: data.zip,
      defaultPolicy: {
        ...data.defaultPolicy,
        policyName: data.defaultPolicy?.name,
      },
      petsAllowed: data.petsAllowed,
      propertyStatistics: data.propertyStatistics,
      propertyInternalId: data.propertyInternalId,
      status: data.status,
      unitNumbers: data.unitNumbers,
      userId: data.userId,
      resourceId: data.resourceId,
      isOnboarded: data.isOnboarded,
      lastSyncDate: data.lastSyncDate
        ? formattedDateTimezone(data.lastSyncDate, 'MM/DD/YYYY HH:mm')
        : 'N/A',
      lastFileExportDate: data.lastFileExportDate
        ? formattedDateTimezone(data.lastFileExportDate, 'MM/DD/YYYY HH:mm')
        : 'N/A',
    };
  },
};

export const propertyManagersTableConverter: TFetchConverter<
  TPropertyManagersTableUI,
  TPropertyManagersTableDB
> = {
  fromDb: (data) => {
    return {
      allowedDelete: data.allowedDelete,
      allowedReInvite: data.allowedReInvite,
      email: data.email,
      name: data.name,
      id: data.id,
      phone: data.phone,
      position: data.position,
      profilePicture: data.profilePicture,
      propertiesCount: data.propertiesCount,
      status: propertyStatusConverter.fromDb(data.status),
    };
  },
};

export const invitePropertyManagerConverter: TCreateConverter<
  TInvitePropertyManagerUI,
  TInvitePropertyMangerDB
> = {
  toDb: (data) => {
    return {
      email: data.email,
      name: data.name,
      phone: `+${data.phone}`,
      position: selectOptionConverter.toDb(data.position),
      properties: data.properties
        ? data.properties.map((property) => property.value)
        : undefined,
      accessToFutureProperties: !!data.accessToFutureProperties,
    };
  },
};

export const addUnitsConverter: TCreateConverter<TAddUnitsUI, TAddUnitsDB> = {
  toDb: (data) => {
    return {
      propertyId: data.propertyId,
      unitNumbers: data.unitNumbers,
    };
  },
};

export const propertyManagerPropertiesTableConverter: TFetchConverter<
  TPropertyManagerPropertiesUI,
  TPropertyManagerPropertiesDB
> = {
  fromDb: (data) => {
    return {
      address1: data.address1,
      type: data.type,
      id: data.id,
      unitCount: data.unitCount,
      name: data.name,
      notificationEnabled: data.notificationEnabled,
    };
  },
};

export const unassignedPropertiesConverter: TFetchConverter<
  TAssignedPropertiesUI,
  TAssignedPropertiesDB
> = {
  fromDb: (data) => {
    return {
      label: data.name,
      value: data.id,
      notificationEnabled: data.notificationEnabled,
    };
  },
};
export const assignedPropertiesConverter: TFetchConverter<
  TUnassignedPropertiesUI,
  TUnassignedPropertiesDB
> = {
  fromDb: (data) => {
    return {
      label: data.name,
      value: data.id,
    };
  },
};
export const assignePropertiesConverter: TCreateConverter<
  TAssignPropertyUI,
  TAssignPropertyDB
> = {
  toDb: (data) => {
    return {
      properties: data.properties.map((item) => item.value),
      propertyManagerId: data.propertyManagerId,
    };
  },
};
export const uploadPetChargeStatusesConverter: TCreateConverter<
  TPetChargeStatusesUploadUI,
  TPetChargeStatusesUploadDB
> = {
  toDb: (data) => {
    const formData = new FormData();
    formData.append('integrationType', data.type.value);
    formData.append('propertyId', data.propertyId);
    data.files.forEach((item) => {
      formData.append('file', item);
    });
    return formData;
  },
};
export const propertyUploadTypeConverter: TFetchConverter<
  EUploadedFileTypeUI,
  EUploadedFileTypeDB
> = {
  fromDb: (data) => {
    const dataMapper: { [key in EUploadedFileTypeDB]: EUploadedFileTypeUI } = {
      [EUploadedFileTypeDB.APPLICANTS]: EUploadedFileTypeUI.APPLICANTS,
      [EUploadedFileTypeDB.RESIDENTS]: EUploadedFileTypeUI.RESIDENTS,
      [EUploadedFileTypeDB.PET_CHARGES]: EUploadedFileTypeUI.PET_CHARGES,
    };
    return dataMapper[data];
  },
};

export const propertyUploadedFilesConverter: TFetchConverter<
  TPropertyUploadedFilesUI,
  TPropertyUploadedFilesDB
> = {
  fromDb: (dbData) => {
    return {
      id: dbData.id,
      fileUrl: dbData.fileUrl,
      fileName: dbData.fileName,
      type: propertyUploadTypeConverter.fromDb(dbData.type),
      exportTime: dbData.exportTime
        ? formattedDateTimezone(dbData.exportTime, dateTimeFormat)
        : 'N/A',
      uploadTime: dbData.uploadTime
        ? formattedDateTimezone(dbData.uploadTime, dateTimeFormat)
        : 'N/A',
      uploaderId: dbData.uploaderId,
      uploaderName: dbData.uploaderName,
    };
  },
};

export const residentsScreeningActionConverter: TCreateConverter<
  TResidentsScreeningActionUI,
  TResidentsScreeningActionDB
> = {
  toDb: (data) => {
    return {
      propertyId: data.propertyId,
      needToScreen: data.needToScreen,
    };
  },
};

export const notificationConverter: TConvertor<
  ENotificationTypesUI,
  ENotificationTypesDB
> = {
  fromDb: (data) => {
    const dataMapper: { [key in ENotificationTypesDB]: ENotificationTypesUI } =
      {
        [ENotificationTypesDB.ANIMAL_APPROVALS_AND_DENIALS]:
          ENotificationTypesUI.ANIMAL_APPROVALS_AND_DENIALS,
        [ENotificationTypesDB.DAILY_ANIMAL_UPDATE_SUMMARY]:
          ENotificationTypesUI.DAILY_ANIMAL_UPDATE_SUMMARY,
        [ENotificationTypesDB.RESIDENT_DOES_NOT_HAVE_ANIMALS]:
          ENotificationTypesUI.RESIDENT_DOES_NOT_HAVE_ANIMALS,

      };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in ENotificationTypesUI]: ENotificationTypesDB } =
      {
        [ENotificationTypesUI.RESIDENT_DOES_NOT_HAVE_ANIMALS]:
          ENotificationTypesDB.RESIDENT_DOES_NOT_HAVE_ANIMALS,
        [ENotificationTypesUI.DAILY_ANIMAL_UPDATE_SUMMARY]:
          ENotificationTypesDB.DAILY_ANIMAL_UPDATE_SUMMARY,
        [ENotificationTypesUI.ANIMAL_APPROVALS_AND_DENIALS]:
          ENotificationTypesDB.ANIMAL_APPROVALS_AND_DENIALS,

      };
    return dataMapper[data];
  },
};

export const propertyMetadataConverter: TFetchConverter<
  TPropertyMetadataUI,
  TPropertyMetadataDB
> = {
  fromDb: (data) => {
    return {
      name: data.name,
      resourceId: data.id,
    };
  },
};
