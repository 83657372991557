export enum EPropertyManagementUrl {
  PET_CHARGING_INFO_PET = 'pet-charging-info',
  PROPERTY_MANAGEMENT = 'property-management',
  PROPERTY_MANAGERS = 'property-managers',
  NAME = 'name',
  ALL = 'all',
  EXPORT = 'export',
  PM_EMAILS = 'pm-emails',
  POSITION = 'position',
  PROFILE_PICTURE = 'profile-picture',
  PHONE = 'phone',
}
