export enum EPropertyUrl {
  PROPERTIES = 'properties',
  UPLOAD = 'upload',
  PREVIEW_UPLOAD = 'preview-upload',
  UNITS = 'units',
  SEARCH = 'search',
  TYPES = 'types',
  TEMPLATE = 'template',
  POLICY = 'policy',
  NAME = 'name',
  PHOTO = 'photo',
  ONBOARD = 'onboard',
  DETAILS = 'details',
  PROPERTY_MANAGERS = 'property-managers',
  INVITATION = 'invitation',
  RESEND = 'resend',
  ASSIGNED = 'assigned',
  UNASSIGN = 'unassign',
  UNASSIGNED = 'unassigned',
  ASSIGN = 'assign',
  PET_CHARGES = 'pet-charges',
  DELETE = 'delete',
  PREVIEW = 'preview',
  UPLOADED_FILES = 'uploaded-files',
  TENANTS_SCREENING = 'existing-tenant-screening',
  FUTURE_PROPERTIES = 'future-properties',
  FUTURE_PROPERTIES_NOTIFICATIONS = 'future-properties-notifications',
  UNIT = 'unit',
  NOTIFICATION = 'notification',
  NOTIFICATIONS = 'notifications',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  ENABLE = 'enable',
  DISABLE = 'disable',
  CONACT = 'contact',
  PRIMARY = 'primary',
  NON_PRIMARY = 'non-primary',
  METADATA = 'metadata',
  PM_NOTIFICATION_SETTINGS = 'pm-notification-settings',
  NOTIFICATION_OPTIONS = 'notification-options',
}
